const baseUrl = 'https://assets.nl-kompetenzzentrum.de'
export default {
  slides: [
    {
      alt: 'Kompetenz­zentrum für Niederlassungs­beratung',
      full: `${baseUrl}/img/large/DÄV-Ansprechpartner.jpg`,
      l: {
        '1x': `${baseUrl}/img/large/DÄV-Ansprechpartner.jpg`
      },
      m: {
        '1x': `${baseUrl}/img/medium/DÄV-Ansprechpartner.jpg`
      },
      s: {
        '1x': `${baseUrl}/img/small/DÄV-Ansprechpartner.jpg`
      },
      xs: {
        '1x': `${baseUrl}/img/xsmall/DÄV-Ansprechpartner.jpg`
      }
    }
  ],
  texts: [
    {
      href: `${baseUrl}/content/team/p1.md`,
      button: [
        {
          label: 'Kontakt aufnehmen',
          href: 'mailto:info@nl-kompetenzzentrum.de'
        }
      ]
    }
  ]
}
